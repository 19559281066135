import React, { useState, useEffect } from 'react';

import NavBar from './NavBar';
import UpdateCard from './UpdateCard';
import ProductCard from './ProductCard';
import WelcomeImage from './Welcome.png';
import DocsImage from './docs.png';
import DiraLogo from './diraIcon.svg';
import etyVpnLogo from './etyVpnLogo.svg';
import etyVpnPromo from './etyVpn.png';
import ponyTown from './ponyTown.png';
import animix from './animix.jpg';

const HomePage = () => {
    const [language, setLanguage] = useState('en'); // Language state

    const toggleLanguage = () => {
        setLanguage(language === 'en' ? 'ru' : 'en');
    };


    useEffect(() => {
        const browserLanguage = navigator.language || navigator.userLanguage;

      
        if (browserLanguage.toLowerCase().includes('ru')) {
            setLanguage('ru');
        } else {
            setLanguage('en');
        }
    }, []);
    return (
        <div>
            <NavBar />
            <div className="content">
                {/* Language toggle button */}


                <h2>{language === 'en' ? 'Recent updates' : 'Последние обновления'}</h2>
                <div className="updates">


                    {language === 'en' ? (
                        <UpdateCard
                            title="New documents center"
                            description="All eternity documents in one place. Stay connected"
                            imgSrc={DocsImage}
                            link="https://documents.ety.one/English"
                        />
                    ) : (
                        <UpdateCard
                            title="Новый сервис с документами."
                            description="Все документы сервисов eternity в одном месте. Остаёмся на связи"
                            link="https://documents.ety.one/Русский"
                            imgSrc={DocsImage}
                        />
                    )}

                    {language === 'en' ? (


                        <UpdateCard
                            title="Welcome to new eternity site!"
                            description="All eternity services in one place. New etyID platform to unite all of our services."
                            imgSrc={WelcomeImage}
                        />
                    ) : (
                        <UpdateCard
                            title="Добро пожаловать на новый сайт eternity!"
                            description="Все сервисы eternity в одном месте. Новая платформа etyID, объединяющая все наши сервисы."
                            imgSrc={WelcomeImage}
                        />
                    )}
                </div>

                <h2>{language === 'en' ? 'Products' : 'Продукты'}</h2>
                <div className="products">
                    <ProductCard
                        title={language === 'en' ? 'Dira' : 'Dira'}
                        description={language === 'en' ? 'Independent messenger' : 'Независимый мессенджер'}
                        imgSrc={DiraLogo}
                        link="https://diranetwork.com"
                    />
                    <ProductCard
                        title={language === 'en' ? 'animix - Anime' : 'animix - Аниме'}
                        description={language === 'en' ? 'Russian anime library' : 'Библиотека аниме на русском языке'}
                        imgSrc={animix}
                        link="https://animix.lol"
                    />
                    <ProductCard
                        title={language === 'en' ? 'Worlds of Pony' : 'Миры Пони'}
                        description={language === 'en' ? 'Fancy game with ponies' : 'Интересная игра с пони'}
                        imgSrc={ponyTown}
                        link="https://play.google.com/store/apps/details?id=ru.oig.ponyworldsonline"
                    />
                </div>

                <div className="footer">
                    <a>eternity 2024</a>
                    <span />
                    <a className="footer-link" href="https://documents.ety.one/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9/">
                        {language === 'en' ? 'Public Offer' : 'Публичная оферта'}
                    </a>
                    <a className="footer-link" href="https://documents.ety.one/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9/">
                        {language === 'en' ? 'Personal Data Processing' : 'Обработка персональных данных'}
                    </a>
                    <a className="footer-link" href="https://documents.ety.one/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9/">
                        {language === 'en' ? 'Subscription Agreement' : 'Соглашение с подпиской'}
                    </a>
                    <a className="footer-link" href="https://documents.ety.one/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9/subscription-agreement/">
                        {language === 'en' ? 'Cancel Subscription' : 'Отмена подписки'}
                    </a>
                    <button onClick={toggleLanguage}>
                        {language === 'en' ? 'Русский язык' : 'English language'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
